<template>
	<div class="container">
		<top-header></top-header>
		<!-- 纵向布局 -->
		<div class="portrait">
			<div class="width-1300" style="text-align: center;">
				<div class="title">
					<p>首页</p>
					<p>home</p>
				</div>
				<router-link to="/design" class="item" v-if="list1">
					<img src="../../../static/images/icon/home3.png" >
				</router-link>
				<router-link to="/testing" class="item" v-if="list2">
					<img src="../../../static/images/icon/home1.png" >
				</router-link>
				<router-link to="/datalake" class="item" v-if="list3">
					<img src="../../../static/images/icon/home2.png" >
				</router-link>
			</div>
		</div>
		<!-- 横向布局 -->
		<div calss="transverse" v-if="false">
			<div class="dx-supplier" ref="supplier">
				<!-- <img src="../../../static/images/index-zs4.jpg" alt=""> -->
				<h2 class="Fromenlarge">世界主流衬板供应商信息</h2>
				<div class="dx-more">
					<p>下滑页面</p>
					<div class="dx-more-img" @click="gotoUrl('/supplier')">
						<img src="../../../static/images/more_img.png" alt="" class="FromUpanddown">
					</div>
				</div>
			</div>
			<div class="dx-operation" ref="operation">
				<div class="disdp_yuan">
					<div class="chancpwk">
						<img src="../../../static/images/img17.png" alt="">
						<h2 class="Fromenlarge">
							<p>世界主要矿山磨机设计及运行数据</p>
						</h2>
						<div class="jueduijz" :class="isAtive == 1 ? 'active' : ''">
							<div class="chancp_svg">
								<div class="show">
									<svg viewBox="0 0 335 71" version="1.1">
										<polyline points="0.34 71.5 78.38 0.5 335.34 0.5"></polyline>
									</svg>
									<div class="chancp_txt">
										<p>矿山信息</p>
									</div>
									<div class="chancp_svgx"></div>
								</div>
								<div class="chancp_svgx" @mouseenter="StopRoll(1)"></div>
							</div>
						</div>
						<div class="jueduijz" :class="isAtive == 2 ? 'active' : ''">
							<div class="chancp_svg">
								<div class="show">
									<svg viewBox="0 0 335 71" version="1.1">
										<polyline points="0.34 71.5 78.38 0.5 335.34 0.5"></polyline>
									</svg>
									<div class="chancp_txt">
										<p>采矿方法</p>
									</div>
									<div class="chancp_svgx"></div>
								</div>
								<div class="chancp_svgx" @mouseenter="StopRoll(2)"></div>
							</div>
						</div>
						<div class="jueduijz" :class="isAtive == 3 ? 'active' : ''">
							<div class="chancp_svg">
								<div class="show">
									<svg viewBox="0 0 335 71" version="1.1">
										<polyline points="0.34 71.5 78.38 0.5 335.34 0.5"></polyline>
									</svg>
									<div class="chancp_txt">
										<p>矿山信息：矿山图片、名称</p>
									</div>
									<div class="chancp_svgx"></div>
								</div>
								<div class="chancp_svgx" @mouseenter="StopRoll(3)"></div>
							</div>
						</div>
						<div class="jueduijz" :class="isAtive == 4 ? 'active' : ''">
							<div class="chancp_svg">
								<div class="show">
									<svg viewBox="0 0 335 71" version="1.1">
										<polyline points="0.34 71.5 78.38 0.5 335.34 0.5"></polyline>
									</svg>
									<div class="chancp_txt">
										<p>矿物特性</p>
									</div>
									<div class="chancp_svgx"></div>
								</div>
								<div class="chancp_svgx" @mouseenter="StopRoll(4)"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="dx-more">
					<p>下滑页面</p>
					<div class="dx-more-img" @click="gotoUrl('/operation')">
						<img src="../../../static/images/more_img.png" alt="" class="FromUpanddown">
					</div>
				</div>
			</div>
			<div class="dx-design" ref="design">
				<!-- <img src="../../../static/images/图片8.png" alt="" class="img1"> -->
				<!-- <img src="../../../static/images/img3.png" alt="" class="img2"> -->
				<h2 class="Fromenlarge">
					<p>磨机衬板辅助设计及数据驱动优化</p>
				</h2>
				<div class="dx-more">
					<p>下滑页面</p>
					<div class="dx-more-img" @click="gotoUrl('/design')">
						<img src="../../../static/images/more_img.png" alt="" class="FromUpanddown">
					</div>
				</div>
			</div>
			<div class="dx-database" ref="database">
				<h2 class="tongh2 tongh2dh">
					磨机衬板磨损点云数据库
				</h2>
				<div class="container">
					<div class="sdhidit_img">
						<img src="../../../static/images/sdidit2.png" alt="">
						<router-link to="/database">
							<div class="sdhidit_x sdhidit_x1" :class="isBase == 0 ? 'active' : ''"
								@mouseenter="StopRollbase(0)" @mouseleave="UpRollnase()">
								<p>墨西哥</p>
								<div class="sdhidit_svg">
									<svg viewBox="0 0 335 71" version="1.1">
										<polyline points="0.34 71.5 78.38 0.5 335.34 0.5"></polyline>
									</svg>
									<div class="sdhidit_txt">
										<img
											src="https://naipumining.com.cn//storage/image/20200619/d00411e5c82f2160b4ffa360cf4eb729.jpg">
										<p>墨西哥奈普采矿机械有限公司。</p>
									</div>
								</div>
							</div>
						</router-link>
						<router-link to="/database">
							<div class="sdhidit_x sdhidit_x2" :class="isBase == 1 ? 'active' : ''"
								@mouseenter="StopRollbase(1)" @mouseleave="UpRollnase()">
								<p>秘鲁</p>
								<div class="sdhidit_svg">
									<svg viewBox="0 0 335 71" version="1.1">
										<polyline points="0.34 71.5 78.38 0.5 335.34 0.5"></polyline>
									</svg>
									<div class="sdhidit_txt">
										<img
											src="https://naipumining.com.cn//storage/image/20200619/842fa99681c067f4f289704a32642f9c.jpg">
										<p>秘鲁奈普矿业有限责任公司</p>
									</div>
								</div>
							</div>
						</router-link>
						<router-link to="/database">
							<div class="sdhidit_x sdhidit_x3" :class="isBase == 2 ? 'active' : ''"
								@mouseenter="StopRollbase(2)" @mouseleave="UpRollnase()">
								<p>智利</p>
								<div class="sdhidit_svg">
									<svg viewBox="0 0 335 71" version="1.1">
										<polyline points="0.34 71.5 78.38 0.5 335.34 0.5"></polyline>
									</svg>
									<div class="sdhidit_txt">
										<img
											src="https://naipumining.com.cn//storage/image/20200622/01112adb66b1c9e81ac271f36e52fd7a.jpg">
										<p>奈普矿山机械智利有限公司。</p>
									</div>
								</div>
							</div>
						</router-link>
						<router-link to="/database">
							<div class="sdhidit_x sdhidit_x4" :class="isBase == 3 ? 'active' : ''"
								@mouseenter="StopRollbase(3)" @mouseleave="UpRollnase()">
								<p>赞比亚</p>
								<div class="sdhidit_svg">
									<svg viewBox="0 0 335 71" version="1.1">
										<polyline points="0.34 71.5 78.38 0.5 335.34 0.5"></polyline>
									</svg>
									<div class="sdhidit_txt">
										<img
											src="https://naipumining.com.cn//storage/image/20200622/2e07a2bd9c66b7f62c1362575713a014.jpg">
										<p>赞比亚奈普矿山机械有限公司。</p>
									</div>
								</div>
							</div>
						</router-link>
					</div>
				</div>
				<!-- <img src="../../../static/images/img4.png" alt=""> -->
				<!-- <h2 class="Fromenlarge">
					<p>磨机衬板磨损点云数据库</p>
				</h2> -->
			
				<div class="dx-more">
					<p>下滑页面</p>
					<div class="dx-more-img" @click="gotoUrl('/database')">
						<img src="../../../static/images/more_img.png" alt="" class="FromUpanddown">
					</div>
				</div>
			</div>
			<div class="dx-monitor" ref="monitor">
				<img src="../../../static/images/img16.jpg" alt="">
				<h2 class="Fromenlarge">
					<p>产品磨损在线监测模块</p>
				</h2>
				<div class="dx-more">
					<p>下滑页面</p>
					<div class="dx-more-img" @click="gotoUrl('/monitor')">
						<img src="../../../static/images/more_img.png" alt="" class="FromUpanddown">
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import Cookie from 'js-cookie'
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter
		},
		inject: ["reload"],
		data() {
			return {
				isAtive: -1,
				isBase: -1,
				list1:false,
				list2:false,
				list3:false,
			};
		},
		created() {
			// console.log(localStorage.getItem('frontRemembertoken'));
			if(!Cookie.get('frontRemembertoken')){
				window.location.href = '/';
			}
			this.routePd();
			// window.addEventListener("scroll", this.handleScroll);
		},
		methods: {
			routePd(){
				let route = JSON.parse(localStorage.getItem("route"));
				//console.log(route);
				if(route == null){
					return;
				}
				for (let i = 0; i < route.length; i++) {
					if(route[i].id == 84){
						this.list1 = true;
					}
					if(route[i].id == 85){
						this.list2 = true;
					}
					if(route[i].id == 86){
						this.list3 = true;
					}
				}
			},
			UpRollnase() {
				this.isBase = -1;
			},
			StopRollbase(index) {
				this.isBase = index;
			},
			StopRoll(index) {
				this.isAtive = index;
				//console.log(index);
			},
			gotoUrl(url) {
				if (this.$route.fullPath != url && url) {
					this.$router.push(url);
					window.scroll(0, 0);
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	body{
		background:url(https://www.naipu.com.cn/html/img/apshjc2.png) no-repeat 0 0;
		background-size: cover;
		height: 100vh;
		margin: 0 0;
	}
	.portrait{
		padding-top: 150px;
		min-height: 94.6vh;
		box-sizing: border-box;
		.title{
			font-size: 30px;
			letter-spacing: 6px;
			text-align: center;
			margin-bottom: 50px;
			p{
				padding-left: 10px;
				box-sizing: border-box;
				&:last-child{
					padding-left: 0;
					font-size: 18px;
					letter-spacing: 0px;
				}
			}
			@media screen and(min-width:320px) and (max-width:767px) {
				font-size: 24px;
				letter-spacing: 5px;
				p{
					&:last-child{
						font-size: 14px;
					}
				}
			}
		}
		.item{
			display: inline-block;
			vertical-align: top;
			width: 33.333%;
			box-sizing: border-box;
			cursor: pointer;
			text-decoration: none;
			img{
				width: 100%;
			}
			@media screen and(min-width:320px) and (max-width:767px) {
				width: 100%;
			}
		}
	}
	.dx-monitor {
		width: 100%;
		height: 100vh;
		position: relative;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;

		&>img {
			height: 100%;
			position: absolute;
		}

		h2 {
			margin: 0 0;
			font-size: 30px;
			color: #fff;
			letter-spacing: 3px;
			text-align: center;
			opacity: 1;
			transform: scale(1);

			@media screen and (min-width:320px) and (max-width:767px) {
				padding: 0 40px;
				box-sizing: border-box;
			}
		}
	}

	.dx-database {
		background: url(../../../static/images/img16.png) no-repeat center;
		background-size: cover;
		overflow: hidden;
		min-height: 100%;
		padding: 7% 0px 6% 0px;
		position: relative;

		.tongh2 {
			color: #fff;
			font-size: 34px;
			margin: 0;
			margin-bottom: 7%;
			text-align: center;
			letter-spacing: 2px;
			opacity: 0;
			position: relative;
			left: -100px;
			opacity: 1;
			left: 0px;
		}

		.container {
			width: 1200px;
			padding: 0;
			margin: 0 auto;

			.sdhidit_img {
				width: 80%;
				position: relative;
				transition: all .5s;

				&>img {
					width: 100%;
					display: block;
				}

				a {
					color: #000;
					cursor: pointer;
				}

				.sdhidit_x {
					position: absolute;
					width: 10px;
					height: 10px;
					border-radius: 100%;
					cursor: pointer;

					&>p {
						width: -webkit-max-content;
						color: #fff;
						margin: 0;
						font-size: 16px;
						position: absolute;
						top: 20px;
						left: 50%;
						transform: translate(-50%, 0%);
						-ms-transform: translate(-50%, 0%);
						-moz-transform: translate(-50%, 0%);
						-webkit-transform: translate(-50%, 0%);
					}

					.sdhidit_svg {
						position: absolute;
						bottom: 7px;
						left: 6px;
						z-index: -1;

						svg {
							width: 280px;
							display: block;
							position: relative;
							z-index: 2;

							polyline {
								fill: none;
								stroke-width: 2px;
								stroke: #ececec;
								-moz-transition: all 1.5s;
								-o-transition: all 1.5s;
								-webkit-transition: all 1.5s;
								transition: all 1.5s;
								stroke-dasharray: 365;
								stroke-dashoffset: 365;
							}
						}

						.sdhidit_txt {
							position: absolute;
							width: 300px;
							left: 100%;
							top: -30px;
							margin-left: -30px;
							background-color: #fff;
							border-radius: 20px;
							overflow: hidden;
							opacity: 0;
							transition: all 1s;

							img {
								width: 100%;
							}

							p {
								margin: 0;
								color: #161617;
								font-size: 16px;
								padding: 10px 17px;
								line-height: 22px;
							}
						}
					}

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0px;
						width: 100%;
						height: 100%;
						border-radius: 100%;
						background-color: #fff;
						z-index: 2;
					}

					&:nth-child(odd) {
						&::after {
							animation: ydun3 2s linear infinite;
							-webkit-animation: ydun3 2s linear infinite;
						}
					}

					&::after {
						content: "";
						position: absolute;
						left: 0;
						top: 0px;
						width: 100%;
						height: 100%;
						border-radius: 100%;
						background-color: rgba(4, 29, 109, 0.77);
						animation: ydun3 3s linear infinite;
						-webkit-animation: ydun3 3s linear infinite;
					}
				}

				.sdhidit_x1 {
					left: 20%;
					top: 45%;
				}

				.sdhidit_x2 {
					left: 29%;
					top: 70%;
				}

				.sdhidit_x3 {
					left: 30%;
					top: 83%;
				}

				.sdhidit_x4 {
					left: 53%;
					top: 68%;
				}

				.active {
					&::before {
						background-color: #0e62d2;
						z-index: 3;
					}

					&::after {
						transform: scale(4.0);
						animation: none !important;
						-webkit-animation: none !important;
						z-index: 2;
					}

					.sdhidit_svg {
						z-index: 2;

						&::before {
							content: "";
							position: absolute;
							right: -7px;
							top: -6px;
							width: 13px;
							height: 13px;
							border-radius: 100%;
							background-color: #fff;
							z-index: 2;
						}

						svg {
							polyline {
								stroke-dashoffset: 0;
							}
						}

						.sdhidit_txt {
							top: -100px;
							opacity: 1;
						}
					}
				}
			}
		}

		// h2 {
		// 	margin: 0 0;
		// 	font-size: 30px;
		// 	color: #fff;
		// 	letter-spacing: 3px;
		// 	text-align: center;
		// 	opacity: 1;
		// 	transform: scale(1);

		// 	@media screen and (min-width:320px) and (max-width:767px) {
		// 		padding: 0 40px;
		// 		box-sizing: border-box;
		// 	}
		// }
	}

	.dx-design {
		width: 100%;
		height: 100vh;
		position: relative;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		background: url(../../../static/images/img3.png) no-repeat center center;
		background-size: cover;
		// & > img {
		// 	height: 100%;
		// 	position: absolute;
		// }
		// .img1 {
		// 	width: 45%;
		// 	height: 100%;
		// }

		// .img2 {
		// 	width: 55%;
		// 	height: 100%;
		// }

		h2 {
			margin: 0 0;
			font-size: 30px;
			letter-spacing: 3px;
			text-align: center;
			opacity: 1;
			transform: scale(1);
			position: absolute;

			@media screen and (min-width:320px) and (max-width:767px) {
				padding: 0 40px;
				box-sizing: border-box;
			}
		}
	}

	.dx-operation {
		background: url(../../../static/images/img14.jpg) no-repeat center;
		background-size: auto 110%;
		min-height: 100%;
		padding: 7% 0px 6% 0px;
		position: relative;

		.disdp_yuan {
			width: 700px;
			height: 700px;
			border-radius: 100%;
			background-color: rgba(51, 51, 51, 0.2);
			margin: 0 auto;
			position: relative;
			// @media screen and (min-width:320px) and (max-width:767px) {
			// 	width: 100%;
			// }
			.chancpwk {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0px;
				top: 0;
				z-index: 2;
				border-radius: 100%;

				&>img {
					display: block;
					width: 45%;
					vertical-align: middle;
					margin-top: 30%;
					margin-left: 27%;
				}
			}

			.Fromenlarge {
				position: absolute;
				left: 20%;
				width: 70%;
				top: 40%;
				font-size: 28px;
				color: #fff;
			}

			.jueduijz {
				position: absolute;
				left: 70%;
				width: 70%;
				top: 30%;
				// opacity: 0;
				z-index: -2;
				transform: translate(-50%, -50%);
				&:nth-child(2) {
					left: 50%;
					top: 30%;

					.show {
						opacity: 0;
					}
				}

				&:nth-child(3) {
					left: 50%;
					top: 90%;

					.show {
						opacity: 0;
					}

					.chancp_svg {
						right: 92%;
					}
				}

				&:nth-child(4) {
					left: 90%;
					top: 30%;

					.chancp_svg {
						right: 0;

						svg {
							transform: rotateY(180deg);
						}

						.show {
							opacity: 0;
							// border: 1px solid red;
							.chancp_svgx {
								left: auto;
								right: 0px;
							}
						}

						.chancp_svgx {
							left: -5px;
							// &:last-child {
							// 	left: auto;
							// 	right: 0px;
							// }
						}

						.chancp_txt {
							right: -53%;
						}
					}
				}

				&:nth-child(5) {
					left: 90%;
					top: 90%;

					.chancp_svg {
						right: 0;

						svg {
							transform: rotateY(180deg);
						}

						.show {
							opacity: 0;

							.chancp_svgx {
								left: auto;
								right: 0px;
							}
						}

						.chancp_svgx {
							left: -5px;
						}

						.chancp_txt {
							right: -53%;

							p {
								width: 120px;
							}
						}
					}
				}
				&:nth-child(6) {
					.show {
						opacity: 0;
					}
				}
				.chancp_svg {
					position: absolute;
					bottom: 105%;
					right: 123%;
					width: 65%;

					svg {
						display: block;

						&:not(:root) {
							overflow: hidden;
						}

						polyline {
							fill: none;
							stroke-width: 2px;
							stroke: #fff;
							transition: all 1.5s;
							stroke-dasharray: 365;
							stroke-dashoffset: -357;
						}
					}

					.chancp_txt {
						position: absolute;
						right: 47%;
						top: -30%;
						width: 65%;
						opacity: 0;
						transition-delay: 2s;
						z-index: -2;

						p {
							color: #fff;
							font-size: 15px;
							line-height: 24px;
							margin: 0;
							padding-left: 5px;
							opacity: 0.9;
						}

						h2 {
							font-size: 24px;
							font-family: IMPACT;
							font-size: 34px;
							color: #262626;
							margin: 0;
							margin-bottom: 15px;
							padding-left: 5px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							margin-top: 15px;
						}
					}

					.show {
						.chancp_svgx {
							right: auto;
							top: auto;
							margin-right: 0px;
							bottom: 0px;
							left: 0px;
							margin-left: -7px;
							margin-bottom: -7px;
							opacity: 0;
							transition-delay: 1.5s;
						}
					}

					.chancp_svgx {
						width: 20px;
						height: 20px;
						margin-right: -5px;
						margin-top: -5px;
						position: absolute;
						right: 0px;
						top: -4px;

						// &:last-child {
						// 	right: auto;
						// 	top: auto;
						// 	margin-right: 0px;
						// 	bottom: 0px;
						// 	left: 0px;
						// 	margin-left: -7px;
						// 	margin-bottom: -7px;
						// 	opacity: 0;
						// 	transition-delay: 1.5s;
						// }

						&::before {
							content: "";
							display: block;
							background-color: #1658af;
							border-radius: 100%;
							width: 100%;
							height: 100%;
							z-index: 2;
							position: relative;
						}

						&::after {
							content: "";
							left: 0%;
							top: 0%;
							width: 100%;
							height: 100%;
							position: absolute;
							border-radius: 100%;
							background-color: #999;
							animation: ydun2 2s linear infinite;
						}
					}
				}
			}

			.active {
				opacity: 1;

				.chancp_svg {
					svg {
						polyline {
							stroke-dashoffset: 0;
						}
					}

					.show {
						opacity: 1 !important;
					}

					.chancp_svgx {
						&:last-child {
							opacity: 1;
						}
					}
				}

				.chancp_txt {
					top: 120% !important;
					opacity: 1 !important;
					z-index: 2 !important;
				}
			}

			&::before {
				content: "";
				left: 25%;
				top: 25%;
				width: 50%;
				height: 50%;
				position: absolute;
				border-radius: 100%;
				background-color: rgba($color: #f5f5f5, $alpha: .8);
			}

			&::after {
				content: "";
				left: 25%;
				top: 25%;
				width: 50%;
				height: 50%;
				position: absolute;
				border-radius: 100%;
				background-color: rgba($color: #f5f5f5, $alpha: .8);
				animation: ydun 3s linear infinite;
			}
		}

		// h2 {
		// 	margin: 0 0;
		// 	color: #fff;
		// 	font-size: 34px;
		// 	letter-spacing: 3px;
		// 	text-align: center;
		// 	opacity: 1;
		// 	transform: scale(1);

		// 	@media screen and (min-width:320px) and (max-width:767px) {
		// 		padding: 0 40px;
		// 		box-sizing: border-box;
		// 	}
		// }
	}

	.dx-supplier {
		width: 100%;
		height: 100vh;
		position: relative;
		// overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;

		// &>img {
		// 	height: 100%;
		// 	position: absolute;
		// } 
		background: url(../../../static/images/index-zs4.jpg) no-repeat center center;
		background-size: cover;

		h2 {
			margin: 0 0;
			font-size: 30px;
			color: #fff;
			letter-spacing: 3px;
			text-align: center;
			opacity: 1;
			transform: scale(1);
			
			@media screen and (min-width:320px) and (max-width:767px) {
				padding: 0 40px;
				box-sizing: border-box;
			}
		}
	}

	@keyframes ydun {
		0% {
			transform: scale(1);
			opacity: 1;
		}

		100% {
			transform: scale(1.3);
			opacity: 0;
		}
	}

	@keyframes ydun2 {
		0% {

			transform: scale(1.0);
			opacity: 1;
		}

		100% {
			transform: scale(2.5);
			opacity: 0;
		}
	}

	@keyframes ydun3 {
		0% {

			transform: scale(1.0);
			opacity: 1;
		}

		100% {
			transform: scale(4.0);
			opacity: 0;
		}
	}
</style>
